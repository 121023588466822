import React, { useState } from "react";
import Ink from "react-ink";
import { useNavigate } from "react-router-dom";
import { GET_ADDRESS_FROM_COORDINATES } from "../../../../../configs";
import Axios from "axios";

const GpsSelector = ({ fetchGpsAutomaticallyAndroid }) => {
	const [gpsLoading, setGpsLoading] = useState(false);
	const navigate = useNavigate();

	const getMyLocation = () => {
		startLoading();
		const location = navigator && navigator.geolocation;
		if (location) {
			location.getCurrentPosition(
				(position) => {
					reverseLookup(position.coords.latitude, position.coords.longitude);
				},
				(error) => {
					setGpsLoading(false);
					stopLoading();
					console.log(error);
					if (navigator.userAgent !== "FoodomaaAndroidWebViewUA") {
						alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
					}
				},
				{ timeout: 5000 }
			);
		} else {
			stopLoading();
		}
	};

	const reverseLookup = (lat, lng) => {
		Axios.post(GET_ADDRESS_FROM_COORDINATES, { lat, lng })
			.then((response) => {
				console.log(response);
				const myLocation = [
					{
						formatted_address: response.data,
						geometry: {
							location: { lat, lng },
						},
					},
				];
				handleGeoLocationClick(myLocation);
			})
			.catch((error) => {
				console.warn(error.response.data);
			});
	};

	const handleGeoLocationClick = (results) => {
		localStorage.setItem("geoLocation", JSON.stringify(results[0]));
		stopLoading();
		if (fetchGpsAutomaticallyAndroid) {
			const userSetAddress = {
				lat: results[0].geometry.location.lat,
				lng: results[0].geometry.location.lng,
				address: results[0].formatted_address,
				house: null,
				tag: null,
				businessLocation: true,
			};
			localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
			localStorage.setItem("userAlreadySelectedLocation", "true");
			window.location.reload();
		} else {
			navigate("/my-location");
		}
	};

	const startLoading = () => {
		setGpsLoading(true);
	};

	const stopLoading = () => {
		setGpsLoading(false);
	};

	return (
		<React.Fragment>
			<div
				className="p-15 d-flex justify-content-center align-items-center gps-selection-block"
				style={{
					backgroundColor: localStorage.getItem("cartColorBg"),
					color: localStorage.getItem("cartColorText"),
					position: "relative",
				}}
				onClick={getMyLocation}
			>
				<div className="mr-15">
					<p className="mb-0">{localStorage.getItem("useGpsMessage")}</p>
				</div>
				<div className="useGpsBtn">{localStorage.getItem("useGpsButtonText")}</div>
				<Ink duration="500" />
				{gpsLoading && <div id="gpsLoadingScreen">Loading...</div>}
			</div>
		</React.Fragment>
	);
};

export default GpsSelector;
