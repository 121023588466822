import { Link, useNavigate } from "react-router-dom";
import React from "react";

export default function DelayLink(props) {
    const { delay, onDelayStart, onDelayEnd, to, replace, clickAction } = props;
    const navigate = useNavigate();
    const timeout = React.useRef(null);

    React.useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);  // Empty array since `timeout` doesn't change

    const handleClick = (e) => {
        if (clickAction) {
            clickAction();
        }
        onDelayStart(e, to);
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();

        timeout.current = setTimeout(() => {
            if (replace) {
                navigate(to, { replace: true });
            } else {
                navigate(to);
            }
            onDelayEnd(e, to);
        }, delay);
    };

    return <Link {...props} onClick={handleClick} />;
}

DelayLink.defaultProps = {
    delay: 0,
    onDelayStart: () => {},
    onDelayEnd: () => {},
};
