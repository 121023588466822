import { FETCH_LOYALTY_POINTS, REDEEM_LOYALTY_POINTS, REMOVE_LOYALTY_POINTS } from './actionTypes';

const initialState = {
  points: 0,
  redeemed: false,
  redeemedPoints: 0,
  loading: true
};

export default function loyaltyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOYALTY_POINTS:
      return {
        ...state,
        points: action.payload,
        loading: false
      };
    case REDEEM_LOYALTY_POINTS:
      return {
        ...state,
        redeemed: true,
        redeemedPoints: action.payload.redeemedPoints
      };
    case REMOVE_LOYALTY_POINTS:
      return {
        ...state,
        redeemed: false,
        redeemedPoints: 0
      };
    default:
      return state;
  }
}