import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Loadable from "react-loadable";
import App from "./components/App";
import Loading from "./components/helpers/loading";
import Root from "./Root";



const NotFound = Loadable({
  loader: () => import("./components/NotFound"),
  loading: () => <Loading />,
});

const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
});

const Login = Loadable({
  loader: () => import("./components/Mobile/Auth"),
  loading: () => <Loading />,
});

const Register = Loadable({
  loader: () => import("./components/Mobile/Auth/Register"),
  loading: () => <Loading />,
});

const CartPage = Loadable({
  loader: () => import("./components/Mobile/Cart"),
  loading: () => <Loading />,
});

const Account = Loadable({
  loader: () => import("./components/Mobile/Account"),
  loading: () => <Loading />,
});

const Explore = Loadable({
  loader: () => import("./components/Mobile/Explore"),
  loading: () => <Loading />,
});

const Addresses = Loadable({
  loader: () => import("./components/Mobile/Account/Addresses"),
  loading: () => <Loading />,
});

const Checkout = Loadable({
  loader: () => import("./components/Mobile/Checkout"),
  loading: () => <Loading />,
});

const RunningOrder = Loadable({
  loader: () => import("./components/Mobile/RunningOrder"),
  loading: () => <Loading />,
});

const Orders = Loadable({
  loader: () => import("./components/Mobile/Account/Orders"),
  loading: () => <Loading />,
});

const WalletPage = Loadable({
  loader: () => import("./components/Mobile/Account/Wallet"),
  loading: () => <Loading />,
});

const Delivery = Loadable({
  loader: () => import("./components/Delivery"),
  loading: () => <Loading />,
});

const DeliveryLogin = Loadable({
  loader: () => import("./components/Delivery/Login"),
  loading: () => <Loading />,
});

const DeliveryOrders = Loadable({
  loader: () => import("./components/Delivery/Orders"),
  loading: () => <Loading />,
});

const ViewOrder = Loadable({
  loader: () => import("./components/Delivery/ViewOrder"),
  loading: () => <Loading />,
});

const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
});

const ItemsParent = Loadable({
  loader: () => import("./components/Mobile/Items/ItemsParent"),
  loading: () => <Loading />,
});

const SingleItemParent = Loadable({
  loader: () => import("./components/Mobile/Items/SingleItemParent"),
  loading: () => <Loading />,
});

const SinglePage = Loadable({
  loader: () => import("./components/SinglePage"),
  loading: () => <Loading />,
});

const ForgotPassword = Loadable({
  loader: () => import("./components/Mobile/Auth/ForgotPassword"),
  loading: () => <Loading />,
});

const RestaurantListOnCategory = Loadable({
  loader: () => import("./components/Mobile/Home/RestaurantListOnCategory"),
  loading: () => <Loading />,
});

const RatingAndReview = Loadable({
  loader: () => import("./components/Mobile/Account/Orders/RatingAndReview"),
  loading: () => <Loading />,
});

const ViewStoreReviews = Loadable({
  loader: () => import("./components/Mobile/StoreReviews"),
  loading: () => <Loading />,
});

const Alerts = Loadable({
  loader: () => import("./components/Mobile/Alerts"),
  loading: () => <Loading />,
});

const FavoriteRestaurantList = Loadable({
  loader: () => import("./components/Mobile/Home/FavoriteRestaurants"),
  loading: () => <Loading />,
});

const LoginAsCustomer = Loadable({
  loader: () => import("./components/Mobile/Modules/LoginAsCustomer"),
  loading: () => <Loading />,
});



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Create the root element for React 18
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Root>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/search-location" element={<Location />} />
        <Route path="/my-location" element={<GeoLocationPage />} />
        <Route
          path="/categories/stores"
          element={<RestaurantListOnCategory />}
        />
        <Route path="/stores/:city?/:restaurant" element={<ItemsParent />} />
        <Route path="/stores/:city?/:restaurant/:id" element={<SingleItemParent />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/my-account" element={<Account />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/my-addresses" element={<Addresses />} />
        <Route path="/my-wallet" element={<WalletPage />} />
        <Route path="/my-orders" element={<Orders />} />
        <Route path="/rate-order/:id" element={<RatingAndReview />} />
        <Route path="/reviews/:slug" element={<ViewStoreReviews />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="/running-order/:unique_order_id"
          element={<RunningOrder />}
        />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/pages/:slug" element={<SinglePage />} />
        <Route
          path="/my-favorite-stores"
          element={<FavoriteRestaurantList />}
        />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/delivery/login" element={<DeliveryLogin />} />
        <Route path="/delivery/orders" element={<DeliveryOrders />} />
        <Route
          path="/delivery/orders/:unique_order_id"
          element={<ViewOrder />}
        />
        <Route path="/delivery/completed-orders" element={<Delivery />} />
        <Route
          path="/auth/login-as-customer/:id?"
          element={<LoginAsCustomer />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </Root>
);
