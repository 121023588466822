import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

let messaging;

const initializeFirebase = async () => {
  if ('serviceWorker' in navigator) {
    const firebaseConfig = {
    apiKey: "AIzaSyBn6VtdBk8PiUg8AVGUhOvOn7syENjX5gQ",
  authDomain: "foodomaa-demo-2af64.firebaseapp.com",
  projectId: "foodomaa-demo-2af64",
  storageBucket: "foodomaa-demo-2af64.appspot.com",
  messagingSenderId: "467039778784",
  appId: "1:467039778784:web:25993de4a7a4ec2cd21d85",
  measurementId: "G-7LQ4YPPXGY"
    };

    const app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);

    try {
      const currentToken = await getToken(messaging, { vapidKey: "BCn3LXpEH_z4piCsidv9P66fhoDwljNF4FuspVOtTFqQCbusJ_ix9H1AMtyNcqnOB8ReDyMElvRdm7r3ndmJ3NY" });
      if (currentToken) {
        console.log('FCM token:', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  }
};

initializeFirebase();

export default messaging;
